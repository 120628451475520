@tailwind base;
@tailwind components;
@tailwind utilities;

.hero {
  background-image: url("../images/HomeImage.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.exhibition {
  background-image: url("../images/homeImage2.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.library {
  background-image: url("../new_images/library1.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

body {
  font-family: Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 960px;
  line-height: 1.4;
  background-color: #fff;
}

@layer utilities {
  .CustomtextSizeTitle {
    font-size: calc(34px + (41 - 34) * ((100vw - 480px) / (960 - 480)));
    font-size: 2rem;
    line-height: 1.37;
  }

  .CustomtextSizeSubTitle {
    font-size: calc(34px + (41 - 34) * ((100vw - 480px) / (960 - 480)));
    font-size: 1.3rem;
    line-height: 1.4;
  }

  .CustomtextSize{
    font-size: 1.2rem;
    line-height: 1.8;
  }

  .CustomtextSizeFooter{
    font-size: 1rem;
    line-height: 1;
  }
}
